.leaflet-container {
  height: 100%;
  width: 100%;
}

html,
body,
#root > *,
.ui.grid {
  height: 100%;
  min-height: 100%;
}

.leaflet-container {
  cursor: inherit !important;
}

.item.disabled {
  opacity: 0.5;
}

.vertex.editing.finished {
  cursor: move !important;
}

.midpoint {
  cursor: copy !important;
}

.vertex:not(.finished).first {
  cursor: cell !important;
}
